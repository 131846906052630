<c-navbar></c-navbar>
<div class="home">
    <!-- Call to action -->
    <section class="home-cta">
        <div class="home-cta-info">
            <h3>{{'Home.Cta.Title' | translate}}</h3>
            <img *ngIf="language === 'en'" class="home-cta-info__bloodmatters"
                src="assets/images/new-home/Blood Matters 2.svg" alt="Mydropmatters" />
            <img *ngIf="language === 'sk'" class="home-cta-info__bloodmatters" alt="Mydropmatters"
                src="assets/images/new-home/krvizalezi.svg" />

            <span class="home-cta-info__action">{{'Home.Cta.Subtitle' | translate}}</span>
            <a href="#download" (click)="scrollToDownload($event)" class="bloody_button">
                <img width="13" height="19" src="assets/images/new-home/Drop_white_icon.svg" alt="White drop" />
                {{'Home.Cta.Button_text' | translate}}
                <div></div>
            </a>
        </div>
        <div class="home-cta-splash">
            <img width="943" height="997" class="home-cta-splash__screen" src="assets/images/new-home/Image_01.webp"
                alt="Blood donation" />

            <div class="home-cta-splash__overlay">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="149"
                    height="149" viewBox="0 0 149 152">
                    <defs>
                        <filter id="Ellipse_16" x="0" y="0" width="227" height="227" filterUnits="userSpaceOnUse">
                            <feOffset dy="13" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="13" result="blur" />
                            <feFlood flood-color="#961919" flood-opacity="0.102" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>

                    <g id="Play_icon" transform="translate(-350 -485)" (click)="toggleModal()">
                        <g id="Group_931" data-name="Group 931" transform="translate(-494.5 221.5)">
                            <g transform="matrix(1, 0, 0, 1, 805.5, 238.5)" filter="url(#Ellipse_16)">
                                <circle id="Ellipse" data-name="Ellipse 16" cx="74.5" cy="74.5" r="74.5"
                                    transform="translate(39 26)" fill="#fff" stroke="currentColor" />
                            </g>
                            <path id="Polygon_1" data-name="Polygon 1"
                                d="M8.942,2.729a2,2,0,0,1,3.4,0l7.04,11.312a2,2,0,0,1-1.7,3.057H3.6a2,2,0,0,1-1.7-3.057Z"
                                transform="translate(930.923 328.551) rotate(90)" fill="#ef2b30" />
                        </g>
                    </g>
                </svg>
                <span>{{'Home.Cta.Play' | translate}}</span>
            </div>
        </div>
    </section>
    <!-- Your company can make difference -->
    <section class="home-diff">
        <div class="home-diff-image">
            <img width="722" height="602" loading="lazy" src="assets/images/new-home/Image_02.webp"
                alt="Blood drop showing blood donation" />
        </div>
        <div class="home-diff-info">
            <h3>{{'Home.Difference.Title' | translate}}</h3>
            <span> {{'Home.Difference.Description' | translate}} </span>
        </div>
    </section>

    <!-- Why take part -->
    <section class="home-why">
        <div class="home-why-background">
            <img width="auto" loading="lazy" class="home-why-background__wave"
                src="assets/images/new-home/Wave_gradient_BG.svg" alt="Pink wave" />
            <img width="100vw" height="10" loading="lazy" class="home-why-background__line"
                src="assets/images/new-home/Wave_Line.svg" alt="Pink wavy underline" />
        </div>
        <div class="home-why-content">
            <h3>{{'Home.Why.Title' | translate}}</h3>
            <!-- 2x2 grid -->
            <div class="home-why-content-grid">
                <div class="home-why-content-grid__item" *ngFor="let icon of gridPartIcons;index as i">
                    <img width="80" height="80" loading="lazy" src="/assets/images/new-home/{{icon}}"
                        alt="{{icon.replace('.svg', '')}}" />
                    <span>{{'Home.Why.Descriptor.'+ i | translate}} </span>
                </div>
            </div>
        </div>
    </section>

    <!-- The power of collective blood donations -->
    <section class="home-donations">
        <div class="home-donations-info">
            <span class="home-donations-info-header">
                <h3>{{'Home.Donations.Title_1' | translate}}</h3>
                <!-- img -->
                <img loading="lazy" *ngIf="language === 'en'" src="assets/images/new-home/Collective Blood.svg"
                    height="50" alt="Collective blood" />
                <img loading="lazy" *ngIf="language === 'sk'" src="assets/images/new-home/darcovstva krvi.svg"
                    height="50" alt="Collective blood" />

                <h3>{{'Home.Donations.Title_2' | translate}}</h3>
            </span>
            <div class="home-donations-list">
                <!-- ng for list -->
                <div class="home-donations-list__item" *ngFor="let i of [0,1,2,3]">
                    <img loading="lazy" src="assets/images/new-home/Drop_icon.svg" alt="Red blood drop" />
                    <span>{{'Home.Donations.Descriptor.'+i | translate}}</span>
                </div>
            </div>
        </div>
        <!-- big image -->
        <img loading="lazy" class="home-donations__image" src="assets/images/new-home/Image 03.png"
            alt="Flower made of drops showing blood donations" />
    </section>
    <!-- testimonials -->
    <section class="home-testimonials">
        <button (click)="slidePrev()" class="home-testimonials-carousel__prev home-carousel__controls--desktop prev">
            <img loading="lazy" src="assets/images/new-home/ArrowLeft.svg" alt="Arrow left" />
        </button>
        <ngx-slick-carousel #carousel class="home-testimonials-carousel" #slickModal="slick-carousel"
            [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="home-testimonials-carousel__slide">
                <img src="assets/images/new-home/Quote 1.svg" alt="Quote made out of drops of blood" />
                <span>{{"Home.Carousel."+slide | translate}}</span>
                <img src="assets/images/new-home/Quote 2.svg" alt="Downwards quote made out of drops of blood" />
            </div>
        </ngx-slick-carousel>
        <button (click)="slideNext()" class="home-testimonials-carousel__next home-carousel__controls--desktop next">
            <img loading="lazy" src="assets/images/new-home/ArrowRight.svg" alt="Arrow right" />
        </button>
        <div class="home-carousel__controls">
            <button (click)="slidePrev()" class="home-testimonials-carousel__prev">
                <img loading="lazy" src="assets/images/new-home/ArrowLeft.svg" alt="Arrow left" />
            </button>
            <button (click)="slideNext()" class="home-testimonials-carousel__next">
                <img loading="lazy" src="assets/images/new-home/ArrowRight.svg" alt="Arrow right" />
            </button>
        </div>
    </section>
    <img width="100vw" height="10" loading="lazy" class="home-carousel__bline"
        src="assets/images/new-home/Wave_Line.svg" alt="wavy underline" />
    <!-- how your company can take part -->
    <section class="home-mdm">
        <div class="home-mdm-left">
            <h3>
                {{'Home.Mdm.Title' | translate}}
                <img loading="lazy" *ngIf="language === 'en'" src="assets/images/new-home/Take Part.svg"
                    alt="red text" />
                <img loading="lazy" *ngIf="language === 'sk'" src="assets/images/new-home/zapojit.svg" alt="red text" />
            </h3>
            <img loading="lazy" class="home-mdm-left__image" src="assets/images/new-home/Image_04.webp" width="704"
                height="589" alt="blood drops showing blood donation" />
        </div>
        <div class="home-mdm-right">
            <h4>{{'Home.Mdm.Description' | translate}}</h4>

            <div class="home-mdm-right__description">
                <h5>{{'Home.Mdm.Descriptor_title' | translate}}</h5>
                <div class="home-mdm-right__description-item" *ngFor="let i of [0,1,2,3]">
                    <img loading="lazy" src="assets/images/new-home/Drop_icon.svg" alt="Droplet" />
                    <div>{{'Home.Mdm.Descriptor.'+i | translate}}</div>
                </div>
            </div>
        </div>
        <img loading="lazy" width="704" height="587" class="home-mdm__image--bottom"
            src="assets/images/new-home/Image_04.webp" alt="blood drops showing blood donation" />
    </section>

    <!-- materials (download section)-->
    <section class="home-materials">
        <div class="home-materials-inner" id="download">
            <div class="home-materials-header">
                <h3 [ngClass]="{'en' : language === 'en'}">{{'Home.Materials.Title' | translate}}</h3>
                <p>
                    {{'Home.Materials.Description.Text' | translate}}
                    <a class="highlight" routerLink="/tos">{{'Home.Materials.Description.Link' |
                        translate}}</a>.
                </p>
            </div>

            <div class="home-materials-contact">
                <a class="download_button" routerLink="/confirmation">{{'Home.Materials.Button_text' |
                    translate}}</a>

                <div class="home-materials-contact__info">
                    <!-- info drop -->
                    <div class="home-materials-contact__info-support">
                        <img loading="lazy" src="assets/images/new-home/Information_icon.svg"
                            alt="Blood drop with I inside" />
                        <p>
                            {{'Home.Materials.Download.Description' |
                            translate}}
                            <a href="mailto:info@mydropmatters.com" class="highlight">info&#64;mydropmatters.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- stats about blood -->
    <section class="home-medicine">
        <div class="home-medicine-header">
            <h3>
                <span class="home-medicine-header__title">{{'Home.Medicine.Title' | translate}}</span>
                <img loading="lazy" *ngIf="language === 'en'" src="assets/images/new-home/Blood Matters 2.svg"
                    alt="red text" />
                <img loading="lazy" *ngIf="language === 'sk'" src="assets/images/new-home/krvizalezi.svg"
                    alt="red text" />
            </h3>

            <p>{{'Home.Medicine.Description' | translate}}</p>
        </div>
        <div class="home-medicine-footer">
            <section>
                <div class="home-medicine-footer__item">
                    <img width="80" height="80" loading="lazy" src="assets/images/new-home/Time_icon.svg"
                        alt="Time icon" />
                    <span>{{'Home.Medicine.Descriptor.0' | translate}}</span>
                </div>
                <div class="home-medicine-footer__item">
                    <img width="80" height="80" loading="lazy" src="assets/images/new-home/People_icon.svg"
                        alt="People icon" />
                    <span>{{'Home.Medicine.Descriptor.2' | translate}}</span>
                </div>
            </section>

            <section>
                <div class="home-medicine-footer__item">
                    <img width="80" height="80" loading="lazy" src="assets/images/new-home/Cells_icon.svg"
                        alt="Cells icon" />
                    <span>{{'Home.Medicine.Descriptor.1' | translate}}</span>
                </div>

                <div class="home-medicine-footer__item">
                    <img width="80" height="80" loading="lazy" src="assets/images/new-home/Superhero_icon.svg"
                        alt="Superhero icon" />
                    <span>{{'Home.Medicine.Descriptor.3' | translate}}</span>
                </div>
            </section>
        </div>
    </section>
    <img width="100vw" height="10" loading="lazy" class="home-medicine__bline"
        src="assets/images/new-home/Wave_Line.svg" alt="underline" />

    <!--  our story -->
    <section class="home-story">
        <div class="home-story__header">
            <h3>{{'Home.Story.Header.Title' | translate}}</h3>
            <p>{{'Home.Story.Header.Description' | translate}}</p>
        </div>

        <div class="home-story-stories">
            <div class="home-story-card" *ngFor="let index of [0,1,2]">
                <span class="home-story-card__description">
                    {{'Home.Story.Stories.'+index+'.Description' | translate}}
                </span>

                <div class="home-story-card__info">
                    <img loading="lazy" src="assets/images/new-home/SpiralLogo.svg" alt="Spiral" />
                    <div class="home-story-card__author">
                        <span class="home-story-card__author-name">
                            {{'Home.Story.Stories.'+index+'.Author.Name' |
                            translate}}
                        </span>
                        <span class="home-story-card__author-job">
                            {{'Home.Story.Stories.'+index+'.Author.Job_Position'
                            | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- join us -->
    <section class="home-joinus">
        <img loading="lazy" width="auto" height="648" class="home-joinus__bg" src="assets/images/new-home/img-ema.webp"
            alt="wavy background showing blood donation" />
        <div class="home-joinus-content">
            <img loading="lazy" *ngIf="language ==='en'" class="home-joinus-content__title"
                src="assets/images/new-home/Join Us.svg" alt="red text" />
            <img loading="lazy" *ngIf="language ==='sk'" class="home-joinus-content__title"
                src="assets/images/new-home/pridajtesa.svg" alt="red text" />
            <p>{{'Home.Joinus.Description' | translate}}</p>
            <img width="100vw" height="10" loading="lazy" class="home-joinus-content__separator"
                src="assets/images/new-home/Wave_Line.svg" alt="wavy underline" />
            <p>{{'Home.Joinus.Joined' | translate}}</p>
            <div class="home-joinus-content__branding">
                <div class="home-joinus-content__branding-logos" (mouseover)="pauseAnimation($event)"
                    (mouseout)="unPauseAnimation($event)" (touchstart)="pauseAnimation($event)"
                    (touchend)="unPauseAnimation($event)">
                    <a class="home-joinus-content__branding-link" href="https://rarecrew.com" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Rarecrew_logo.svg" alt="Rarecrew logo" />
                    </a>

                    <a class="home-joinus-content__branding-link"
                        href="{{language === 'sk' ? 'https://www.zatopek.org/?lang=sk' : 'https://www.zatopek.org'}}"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Zatopek Logo.svg" alt="Zatopek logo" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://zakasarnou.sk/_sub/prod/index.php"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/zakasarnou.sk.webp" alt="Zakasarnou logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://paralympic.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/logoSPVrgb1767px.webp" alt="SPV logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://sztps.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/SZTPS logo.webp" alt="SZTPS logo" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://www.gjh.sk" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/LogoZSKosicka.webp"
                            alt="Spojená škola Novohradská" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://www.gjh.sk" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/LogoGJH.webp" alt="Gymnázium Jura Hronca" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://felixzilina.edupage.org/"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Felix logo.webp"
                            alt="Sukromná základná škola Felix" />
                    </a>
                    <a class="home-joinus-content__branding-link" href=" https://zscaba.edupage.org/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/cadrova_logo.jpg" alt="Zscaba logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://www.zsodborba.edu.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Logo Odborárska.png" alt="Zsodborba Edu logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://zsdolnatizina.edupage.org/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/logo_zs_dolná tižina.jpeg" alt="ZS DOLNA TIZINA logo" />
                    </a>
                    <!--  -->
                    <a class="home-joinus-content__branding-link" href="https://rarecrew.com" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Rarecrew_logo.svg" alt="Rarecrew logo" />
                    </a>

                    <a class="home-joinus-content__branding-link"
                        href="{{language === 'sk' ? 'https://www.zatopek.org/?lang=sk' : 'https://www.zatopek.org'}}"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Zatopek Logo.svg" alt="Zatopek logo" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://zakasarnou.sk/_sub/prod/index.php"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/zakasarnou.sk.webp" alt="Zakasarnou logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://paralympic.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/logoSPVrgb1767px.webp" alt="SPV logo" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://sztps.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/SZTPS logo.webp" alt="SZTPS logo" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://www.gjh.sk" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/LogoZSKosicka.webp"
                            alt="Spojená škola Novohradská" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://www.gjh.sk" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/LogoGJH.webp" alt="Gymnázium Jura Hronca" />
                    </a>
                    <a class="home-joinus-content__branding-link" href="https://felixzilina.edupage.org/"
                        target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Felix logo.webp"
                            alt="Sukromná základná škola Felix" />
                    </a>
                    <a class="home-joinus-content__branding-link" href=" https://zscaba.edupage.org/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/cadrova_logo.jpg" alt="ZS Cadrova logo" />
                    </a>

                    <a class="home-joinus-content__branding-link" href="https://www.zsodborba.edu.sk/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/Logo Odborárska.png" alt="Zsodborba Edu logo" />
                    </a>
                    
                    <a class="home-joinus-content__branding-link" href="https://zsdolnatizina.edupage.org/" target="_blank">
                        <img loading="lazy" src="assets/images/new-home/logo_zs_dolná tižina.jpeg" alt="ZS DOLNA TIZINA logo" />
                    </a>
                </div>

                <div class="join__action">
                    {{'Home.Joinus.Button' | translate}}
                </div>
            </div>
        </div>
    </section>
</div>
<c-footer></c-footer>

<!-- fullscreen modal with youtube video -->
<c-modal [isOpen]="isModalOpen" (toggle)="toggleModal()"></c-modal>
<cookie-banner></cookie-banner>